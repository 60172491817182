import {getLocale} from 'next-intl/server';
import Cookies from 'universal-cookie';
import {getAccessToken} from '@/actions/auth';

export function getApiRoot(service: string) {
  // Client side
  if (typeof window !== 'undefined') {
    return process.env.NEXT_PUBLIC_API_ENDPOINT_CLIENT_SIDE;
  }

  // Server side
  return process.env.NODE_ENV !== 'production'
    ? `http://${service}`
    : process.env.NEXT_PUBLIC_API_ENDPOINT;
}

export function getApisRoot(service: string, prefix: string) {
  // Client side
  if (typeof window !== 'undefined') {
    return `${process.env.NEXT_PUBLIC_APIS_ENDPOINT_CLIENT_SIDE}${prefix}`;
  }

  // Server side
  return `http://${service}`;
}

export async function getHeaders() {
  const locale =
    typeof window !== 'undefined'
      ? new Cookies().get('locale')
      : await getLocale();
  const accessToken = await getAccessToken();
  return {
    ...(accessToken && {Authorization: `JWT ${accessToken}`}),
    'Accept-Language': locale,
    'X-Winelivery-App-Platform': 'web',
    'Content-Type': 'application/json',
  };
}
